import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import HomeContainer from "./containers/Home";
import AuthContainer from "./containers/Auth";
import { getAuthToken } from "./storage/authStorage";

import "./App.scss";

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  const token = getAuthToken();
  return <Route {...rest} render={(props) => (token ? <Component {...props} /> : <Redirect to="/login" />)} />;
}

export default function App() {
  return (
    <Router>
      <Switch>
        {/* Auth */}
        <Route exact path="/login" component={AuthContainer} />
        <Route exact path="/recovery" component={AuthContainer} />
        {/* Main */}
        <PrivateRoute path="/" component={HomeContainer} />
        <PrivateRoute exact path="/users" component={HomeContainer} />
        <PrivateRoute exact path="/settings" component={HomeContainer} />
        <PrivateRoute exact path="/destinations" component={HomeContainer} />
        <PrivateRoute exact path="/destinations/create" component={HomeContainer} />
        <PrivateRoute exact path="/partners" component={HomeContainer} />
        <PrivateRoute exact path="/examples" component={HomeContainer} />
      </Switch>
    </Router>
  );
}
