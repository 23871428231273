import { postRequest } from "../helpers/fetchHandler";
import { clearAuthToken, setAuthToken } from "../storage/authStorage";
import {
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_LOADING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOG_OUT
} from "../constants/actionTypes";

export function logout() {
  return async (dispatch) => {
    await clearAuthToken();
    return dispatch({ type: LOG_OUT });
  };
}

export function login(email, code) {
  return (dispatch) => {
    dispatch({ type: LOGIN_LOADING });

    return postRequest({ url: "/login/", data: { username: email, password: code } })
      .then((data) => setAuthToken(data.token))
      .then((data) => dispatch({ type: LOGIN_SUCCESS, payload: data, meta: { email } }))
      .catch((error) => {
        dispatch({ type: LOGIN_FAILED, error });
        return Promise.reject(error);
      });
  };
}

export function register(data) {
  return (dispatch) => {
    dispatch({ type: REGISTER_LOADING });
    const { email } = data;

    return postRequest({ url: "/api/register/", data })
      .then((data) => dispatch({ type: REGISTER_SUCCESS, payload: data, meta: { email } }))
      .catch((error) => {
        dispatch({ type: REGISTER_FAILED, error });
        return Promise.reject(error);
      });
  };
}
