import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import AllDestinationsContainer from "../Destinations/All";
import CreateDestinationContainer from "../Destinations/Create";
import GuidesContainer from "../Guides";
import EditGuideContainer from "../Guides/Edit";
import DestinationSettingsContainer from "../Settings";
import PartnersContainer from "../Partners";
import ExamplesContainer from "../Examples";
import ErrorContainer from "../Error/404/index";
import MissingDestinationContainer from "../Error/destination/index";

import { logout } from "../../actions/auth";
import { fetchAllDestinations, selectDestination } from "../../actions/destinations";

class HomeContainer extends Component {
  componentDidMount() {
    this.fetchDestinations();
  }

  componentDidUpdate(prevProps) {  // Dirty fixup
    const { destinations, selectedDestinationId } = prevProps;
    const selectedDestination = destinations.find(destination => destination.id === selectedDestinationId);

    if (!selectedDestination && destinations.length) {
      this.props.selectDestination(destinations[0].id);
    }
  }

  fetchDestinations = () => {
    this.props
      .fetchAllDestinations()
      .then((resp) => resp.payload)
      .then((destinations) => {
        if (destinations.length === 0) {
          this.props.history.push("/destinations/create");
        } else if (!this.props.selectedDestinationId) {
          this.selectDestination(destinations[0]);
        }
      })
      .catch(() => {});
  };

  logout = () => {
    this.props
      .logout()
      .then(() => {
        this.props.history.push("/login");
      })
      .catch(() => {});
  };

  selectDestination = (destination) => {
    this.props
      .selectDestination(destination.id)
      .then(() => {
        this.props.history.push("/guides");
      })
      .catch(() => {});
  };

  render() {
    const { user, destinations, selectedDestinationId } = this.props;
    const selectedDestination = destinations.find(destination => destination.id === selectedDestinationId);

    return (
      <>
        <Header logout={this.logout} user={user} />
        <Container fluid className={"has-sidebar has-header"}>
          <Row>
            <Col xs={12}>
              <Switch>
                {/* Visible all registered users */}
                <Route exact path="/examples" component={ExamplesContainer} />
                <Route exact path="/partners" component={PartnersContainer} />
                <Route exact path="/" render={() => (<Redirect to="/destinations" />)} />
                <Route exact path="/destinations" component={AllDestinationsContainer} />
                <Route exact path="/destinations/create" component={CreateDestinationContainer} />
                {/* Visible only if user have destinations */}
                { !destinations.length && <Route path="*" component={MissingDestinationContainer} /> }
                <Route exact path="/guides" component={GuidesContainer} />
                <Route exact path="/guides/:id" component={EditGuideContainer} />
                <Route exact path="/settings" component={DestinationSettingsContainer} />
                {/* Error page */}
                <Route path="*" component={ErrorContainer} />
              </Switch>
            </Col>
          </Row>
        </Container>
        <Sidebar
          destinations={destinations}
          selectDestination={this.selectDestination}
          selectedDestination={selectedDestination}
        />
      </>
    );
  }
}

HomeContainer.propTypes = {
  fetchAllDestinations: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  selectDestination: PropTypes.func.isRequired,
  user: PropTypes.shape(),
  selectedDestinationId: PropTypes.number,
  destinations: PropTypes.arrayOf(PropTypes.shape())
};

HomeContainer.defaultProps = {
  selectedDestinationId: null,
  user: null,
  destinations: []
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  destinations: state.destinations.destinations,
  selectedDestinationId: state.destinations.selectedDestinationId
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllDestinations: () => dispatch(fetchAllDestinations()),
  logout: () => dispatch(logout()),
  selectDestination: (id) => dispatch(selectDestination(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
