import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Col, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";

const validationSchema = (t) => {
  return Yup.object({
    first_name: Yup.string()
      .min(2, t("auth.validation.invalidMinLength"))
      .max(30, t("auth.validation.invalidMaxLength"))
      .required(t("auth.validation.required")),
    last_name: Yup.string()
      .min(2, t("auth.validation.invalidMinLength"))
      .max(30, t("auth.validation.invalidMaxLength"))
      .required(t("auth.validation.required")),
    email: Yup.string()
      .email(t("auth.validation.invalidEmail"))
      .required(t("auth.validation.required")),
    terms: Yup.boolean()
      .required(t("auth.validation.invalidTerms"))
      .oneOf([true], t("auth.validation.invalidTerms"))
  });
};

const displayIF = (boolean) => {
  return { display: boolean ? "block" : "none" };
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register = ({ onSubmit, isLoading }) => {
  const [promoVisibility, setPromoVisibility] = useState(false);
  const query = useQuery();
  const { t } = useTranslation();

  return (
    <Container>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          business: "",
          business_id: "",
          promo_code: query.get("code") || "",
          email: "",
          terms: false
        }}
        validationSchema={validationSchema(t)}
        validateOnBlur={false}
        onSubmit={(values) => onSubmit(values)}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row className="mb-3">
              <Form.Group as={Col} xs={12} xl={6} controlId="RegisterFormFirstname" className="position-relative">
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder={t("auth.form.first_name")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  isInvalid={touched.first_name && errors.first_name}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} xl={6} controlId="RegisterFormLastname" className="position-relative">
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder={t("auth.form.last_name")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  isInvalid={touched.last_name && errors.last_name}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormEmail" className="position-relative">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder={t("auth.form.email")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={touched.email && errors.email}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormBusiness" className="position-relative">
                <Form.Control
                  type="text"
                  name="business"
                  placeholder={t("auth.form.business")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.business}
                  isInvalid={touched.business && errors.business}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.business}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormBusinessId" className="position-relative">
                <Form.Control
                  type="text"
                  name="business_id"
                  placeholder={t("auth.form.business_id")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.business_id}
                  isInvalid={touched.business_id && errors.business_id}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.business_id}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12}
                controlId="RegisterFormPromoCode"
                className="position-relative" style={displayIF(promoVisibility)}>
                <Form.Control
                  type="text"
                  name="promo_code"
                  placeholder={t("auth.form.promo_code")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.promo_code}
                  isInvalid={touched.promo_code && errors.promo_code}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.promo_code}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormTerms" className="text-left position-relative">
                <Form.Check
                  type="checkbox"
                  name="terms"
                  label={(
                    <>
                      <span>{t("auth.form.accept")} </span>
                      <a className="underline" 
                        href="https://www.gettips.fi/terms-of-service" 
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("auth.form.terms")}
                      </a>
                    </>
                  )}
                  onChange={handleChange}
                  value={values.terms}
                  isInvalid={touched.terms && errors.terms}
                />
                <Form.Control.Feedback tooltip type="invalid" style={displayIF(touched.terms && errors.terms)}>
                  {errors.terms}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="mb-3">
              <Col xs={12} style={displayIF(!promoVisibility)}>
                <Button variant="link" className="a" onClick={() => setPromoVisibility(true)}>
                  {t("auth.button.promoCode")}
                </Button>
              </Col>
            </Form.Row>
            <Form.Row className="mb-3">
              <Col lg={{ span: 8, offset: 2 }} xs={12}>
                <Button block variant="primary" type="submit" disabled={isLoading}>
                  {t("auth.button.signup")}
                </Button>
              </Col>
            </Form.Row>
            <Form.Row className="mb-2 small">
              <Col>
                <Trans i18nKey="auth.button.alreadyAccount">
                  <Link className="underline" to="/login"></Link>
                </Trans>
              </Col>
            </Form.Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

Register.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};
Register.defaultProps = {};

export default Register;
