import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FileUploader = (props) => {
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };

  return (
    <>
      {props.tooltip ? (
        <OverlayTrigger placement="right" overlay={<Tooltip>{props.tooltip}</Tooltip>}>
          { React.cloneElement( props.children, { onClick: handleClick } ) }
        </OverlayTrigger>
      ) : React.cloneElement( props.children, { onClick: handleClick } )}
      <input
        accept={props.accept}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      /> 
    </>
  );
};

FileUploader.propTypes = {
  handleFile: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  accept: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

FileUploader.defaultProps = {
  tooltip: null
};

export default FileUploader;
