/* eslint-disable */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Nav, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiOutlineNewspaper, HiOutlineCog, HiMenu, HiX } from "react-icons/hi";
import logo from "../assets/levi_logo.svg";
import DestinationPicker from "./destinationPicker";

function Sidebar({ destinations, selectedDestination, selectDestination }) {
  const [visible, setVisibility] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Col className={"px-0 sidebar-navigation " + (visible ? "show" : "hide")}>
        <Nav activeKey={location && location.pathname.split("/")[1]} className="flex-column nav-container">
          <img src={logo} alt="visit levi logo" className="logo" />
          <DestinationPicker 
            destinations={destinations}
            selectedDestination={selectedDestination}
            selectDestination={selectDestination} 
          />
          <Nav.Link as={Link} eventKey="guides" to="/guides"><HiOutlineNewspaper /> {t("sidebar.guides")}</Nav.Link>
          <Nav.Link as={Link} eventKey="settings" to="/settings"><HiOutlineCog /> {t("sidebar.settings")}</Nav.Link>
          <span className="nav-spacer" />
          <p className="nav-title text-uppercase">{t("header.details")}</p>
          <Nav.Link as={Link} eventKey="examples" to="/examples">{t("sidebar.examples")}</Nav.Link>
          <Nav.Link as={Link} eventKey="partners" to="/partners">{t("sidebar.partners")}</Nav.Link>
          <footer className="mt-auto mb-3">
            <span className="powered-by">Powered by <a href="https://gettips.fi" target="_blank">Get Tips Oy</a></span>
          </footer>
        </Nav>
      </Col>
      <Button
        variant="secondary" 
        onClick={() => setVisibility(!visible)}
        className={"sidebar-toggle " + (visible ? "open" : "closed")}>
        { visible ? <HiX /> : <HiMenu /> } 
      </Button>
    </>
  );
};

Sidebar.propTypes = {};
Sidebar.defaultProps = {};

export default Sidebar;
