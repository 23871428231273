import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiOutlineRefresh, HiPlus } from "react-icons/hi";
import { withTranslation } from "react-i18next";

import { fetchAllDestinations, selectDestination, deleteDestination } from "../../../actions/destinations";
import { Destination, Paginations } from "./components";
import ConfirmationModal from "../../../components/confirmation";

class AllDestinationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { selected: 1, min: 0, max: 5 },
      toast: { display: false, type: null, message: null },
      confirmation: {
        show: false,
        callback: null,
        title: "",
        content: ""
      }
    };
  }

  fetchDestinations = () => {
    this.props.fetchAllDestinations().catch(() => {});
  };

  onSelect = (destination) => {
    this.props
      .selectDestination(destination.id)
      .then(() => {
        this.props.history.push("/guides");
      })
      .catch(() => {});
  };

  onEdit = (destination) => {
    this.props
      .selectDestination(destination.id)
      .then(() => {
        this.props.history.push("/destinations/edit");
      })
      .catch(() => {});
  };

  delete = (destination) => {
    this.props
      .deleteDestination(destination.id)
      .then(() => {
        this.props.history.push("/destinations");
      })
      .catch(() => {});
  };

  onDelete = (destination) => {
    this.setState({
      confirmation: {
        show: true,
        callback: () => {
          this.hideConfirmation();
          this.delete(destination);
        },
        title: "delete",
        content: "delete.destination",
        danger: true
      }
    });
  };

  onPaginationSelect = (value) => {
    this.setState({
      pagination: {
        selected: value,
        min: (value - 1) * 5,
        max: value * 5
      }
    });
  };

  hideConfirmation = () => {
    this.setState({
      confirmation: {
        show: false,
        callback: null,
        title: "",
        content: ""
      }
    });
  };

  setToast = (display, type, message, values) => {
    this.setState({ toast: { display, type, content: { message, values } } });
  };

  onCopyToClipboard = (data) => {
    this.setToast(true, "success", "success");
    navigator.clipboard.writeText(data);
  };

  render() {
    const { t, destinations, isLoading } = this.props;
    const {
      toast,
      confirmation,
      pagination: { selected, min, max }
    } = this.state;

    return (
      <Container fluid>
        {toast && toast.display && (
          <Toast
            className={"fixed-toast " + toast.type}
            onClose={() => this.setToast(false)}
            show={toast.display}
            delay={5000}
            autohide>
            <Toast.Header>
              <strong className="mr-auto">{t("common.notifications.type." + toast.type)}</strong>
            </Toast.Header>
            <Toast.Body>{t("destinations.all.notifications." + toast.content.message)}</Toast.Body>
          </Toast>
        )}
        <ConfirmationModal
          show={confirmation.show}
          title={confirmation.title}
          content={confirmation.content}
          danger={confirmation.danger}
          onOK={confirmation.callback}
          onCancel={this.hideConfirmation}
        />
        <Row>
          <Col xs={12}>
            <h3 className="mb-0 font-weight-bold">{t("destinations.all.title")}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <h3 className="mb-4 font-weight-bold">
              <span className="small text-muted">
                {t("destinations.all.subtitle", { length: destinations.length })}
              </span>
            </h3>
          </Col>
          <Col xs={12} md={6} className="text-md-right mb-4 mb-md-0">
            <Button
              variant="primary"
              className="btn-default"
              disabled={isLoading}
              onClick={this.fetchDestinations}>
              <HiOutlineRefresh className={isLoading ? "slow-spin icon mr-2" : "icon mr-2"} />
              {t("destinations.all.button.refresh")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {destinations.slice(min, max).map((destination, index) => (
              <Destination
                destination={destination}
                key={index}
                onSelect={this.onSelect}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                onCopyToClipboard={this.onCopyToClipboard}
              />
            ))}
          </Col>
        </Row>
        <Row className="mb-4 mt-2 text-md-right">
          <Col>
            <Paginations total={destinations.length} active={selected} onSelect={this.onPaginationSelect} />
          </Col>
        </Row>
      </Container>
    );
  }
}

AllDestinationsContainer.propTypes = {
  fetchAllDestinations: PropTypes.func.isRequired,
  selectDestination: PropTypes.func.isRequired,
  deleteDestination: PropTypes.func.isRequired,
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number
    })
  ),
  isLoading: PropTypes.bool
};

AllDestinationsContainer.defaultProps = {
  destinations: [],
  isLoading: false
};

const mapStateToProps = (state) => ({
  destinations: state.destinations.destinations,
  isLoading: state.destinations.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllDestinations: () => dispatch(fetchAllDestinations()),
  selectDestination: (id) => dispatch(selectDestination(id)),
  deleteDestination: (id) => dispatch(deleteDestination(id))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AllDestinationsContainer));
