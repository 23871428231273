import { getRequest, postRequest, putRequest, deleteRequest } from "../helpers/fetchHandler";
import {
  DESTINATIONS_LOADING,
  DESTINATIONS_SUCCESS,
  DESTINATIONS_FAILED,
  CREATE_DESTINATION_LOADING,
  CREATE_DESTINATION_SUCCESS,
  CREATE_DESTINATION_FAILED,
  UPDATE_DESTINATION_LOADING,
  UPDATE_DESTINATION_SUCCESS,
  UPDATE_DESTINATION_FAILED,
  DELETE_DESTINATION_LOADING,
  DELETE_DESTINATION_SUCCESS,
  DELETE_DESTINATION_FAILED,
  DESTINATION_SELECTED,
  DESTINATION_UNSELECTED,
  EXAMPLE_DESTINATION_LOADING,
  EXAMPLE_DESTINATION_SUCCESS,
  EXAMPLE_DESTINATION_FAILED,
} from "../constants/actionTypes";

// Require authorization
export function fetchAllDestinations() {
  return (dispatch) => {
    dispatch({ type: DESTINATIONS_LOADING });

    const url = "/api/destinations/";
    return getRequest({ url, auth: true })
      .then((data) => dispatch({ type: DESTINATIONS_SUCCESS, payload: data }))
      .catch((error) => {
        dispatch({ type: DESTINATIONS_FAILED, error });
        return Promise.reject(error);
      });
  };
}

// Require authorization
export function createDestination(data) {
  return (dispatch) => {
    dispatch({ type: CREATE_DESTINATION_LOADING });

    const url = "/api/destinations/";
    return postRequest({ url, data, auth: true })
      .then((response) => dispatch({ type: CREATE_DESTINATION_SUCCESS, payload: response }))
      .catch((error) => {
        dispatch({ type: CREATE_DESTINATION_FAILED, error });
        return Promise.reject(error);
      });
  };
}

// Require authorization
export function updateDestination(id, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_DESTINATION_LOADING });

    const url = `/api/destinations/${id}/`;
    return putRequest({ url, data, auth: true })
      .then((values) => dispatch({ type: UPDATE_DESTINATION_SUCCESS, payload: values }))
      .catch((error) => {
        dispatch({ type: UPDATE_DESTINATION_FAILED, error });
        return Promise.reject(error);
      });
  };
}

// Require authorization
export function deleteDestination(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_DESTINATION_LOADING });

    const url = `/api/destinations/${id}/`;
    return deleteRequest({ url, auth: true })
      .then(() => dispatch({ type: DELETE_DESTINATION_SUCCESS, payload: id }))
      .catch((error) => {
        dispatch({ type: DELETE_DESTINATION_FAILED, error });
        return Promise.reject(error);
      });
  };
}

export function selectDestination(destinationId) {
  return (dispatch) => {
    dispatch({ type: DESTINATION_SELECTED, payload: destinationId });
    return Promise.resolve();
  };
}

export function unselectDestination() {
  return (dispatch) => {
    dispatch({ type: DESTINATION_UNSELECTED });
    return Promise.resolve();
  };
}


export function fetchExampleDestination() {
  return dispatch => {
      dispatch({ type: EXAMPLE_DESTINATION_LOADING });

      const url = "/api/travellerdestination/EXAMPLE";
      return getRequest({ url })
          .then(data => dispatch({ type: EXAMPLE_DESTINATION_SUCCESS, payload: data }))
          .catch(error => {
              dispatch({ type: EXAMPLE_DESTINATION_FAILED, error });
              return Promise.reject(error);
          });
  }
}
