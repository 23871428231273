// Pass values: (source, order, "id")

// Example:
// const source = [
//     {id: 2, label: "Two"},
//     {id: 3, label: "Three"},
//     {id: 1, label: "Five"},
// ]
// const order = [1, 2, 3]

export const mapOrder = (a, order, key) => {
  if (!order || !key) {
      return a;
  }
  // eslint-disable-next-line no-sequences
  const map = order.reduce((r, v, i) => ((r[v] = i), r), {})
  return a.sort((a, b) => map[a[key]] - map[b[key]])
}
