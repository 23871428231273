import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, Row, Col, Button, Modal, Form, Toast } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { HiAnnotation } from "react-icons/hi";

import { postFeedback } from "../actions/feedback";

const validationSchema = (t) => {
  return Yup.object({
    name: Yup.string(),
    email: Yup.string()
      .email(t("feedback.validation.invalidEmail")),
    subject: Yup.string(),
    waiting_reply: Yup.boolean(),
    feedback: Yup.string()
      .max(2000, t("feedback.validation.invalidMaxLength"))
      .required(t("feedback.validation.required")),
  })
};

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toast: { display: false, type: null, message: null },
      displayModal: false
    };
  }

  setToast = (display, type, message, values) => {
    this.setState({ toast: { display, type, content: { message, values }}});
  }

  handleShow = () => {
    this.setState({ displayModal: true });
  }

  handleClose = () => {
    this.setState({ displayModal: false });
  }

  onSubmit = (values) => {
    const data = {
      ...values,
      destination: this.props.destinationId,
      app_version: "Web " + process.env.REACT_APP_VERSION + " (" + process.env.REACT_APP_BUILD + ")"
    }

    this.props.postFeedback(data)
      .then(this.handleClose)
      .then(() => this.setToast(true, "success", "success"))
      .catch(e => this.setToast(true, "error", "failed"));
  }

  render () {
    const { t, user, isLoading } = this.props;
    const { toast, displayModal } = this.state;

    return (
      <>
        <Nav.Link onClick={this.handleShow}><HiAnnotation /></Nav.Link>

        {toast && toast.display && (
          <Toast 
            className={"fixed-toast " + toast.type} 
            onClose={() => this.setToast(false)} 
            show={toast.display} 
            delay={5000} autohide>
            <Toast.Header>
              <strong className="mr-auto">{t("common.notifications.type." + toast.type)}</strong>
            </Toast.Header>
            <Toast.Body>{t("feedback.notifications." + toast.content.message)}</Toast.Body>
          </Toast>
        )}

        <Modal show={displayModal} onHide={this.handleClose}>
          <Formik
            key={"Formik-edit-guide"}
            enableReinitialize={true}
            initialValues={{
              name: "",
              email: user?.email || "",
              subject: "FEEDBACK",
              feedback: "",
              waiting_reply: false
            }}
            validationSchema={validationSchema(t)}
            validateOnBlur={false}
            onSubmit={this.onSubmit}>
            {({ handleSubmit, ...props }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <div className="modal-title h5">{t("feedback.title")}</div>
                </Modal.Header>

                <Modal.Body>
                  <p className="small">{t("feedback.info")}</p>
                  <Row>
                    <Col xs={6}>
                      <p className="small">
                        <b>{t("feedback.contact.technical")}</b><br />
                        Get Tips Oy<br />
                        Gösta Serlachius +358503701974<br/>
                        Arno Ranta-aho +358400785203
                      </p>
                    </Col>
                    <Col xs={6}>
                      <p className="small">
                        <b>{t("feedback.contact.clients")}</b><br />
                        Levin Matkailu Oy<br />
                        Minna Mikkonen +358407218226
                      </p>
                    </Col>
                  </Row>
                  <hr />
                  <Form.Row>
                    {/* Basic details */}
                    <Col xs={6}>
                      <Form.Group controlId="FeedbackName" className="position-relative">
                        <Form.Text className="text-muted">{t("feedback.form.name")}</Form.Text>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=""
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.name}
                          isInvalid={props.touched.name && props.errors.name} />
                        <Form.Control.Feedback tooltip type="invalid">
                          {props.errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="FeedbackEmail" className="position-relative">
                        <Form.Text className="text-muted">{t("feedback.form.email")}</Form.Text>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder=""
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.email}
                          isInvalid={props.touched.email && props.errors.email} />
                        <Form.Control.Feedback tooltip type="invalid">
                          {props.errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                      <Form.Group controlId="CreateDestinationFormCountry" className="position-relative">
                        <Form.Text className="text-muted">{t("feedback.form.subject")}</Form.Text>
                        <Form.Control
                          custom
                          as="select"
                          type="text"
                          name="subject"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.subject}
                          isInvalid={props.touched.subject && props.errors.subject}
                          className={props.touched.subject && props.errors.subject ? "has-error" : null}>
                          <option value="FEEDBACK">{t("feedback.form.subjects.feedback")}</option>
                          <option value="DEVELOPMENT">{t("feedback.form.subjects.development")}</option>
                          <option value="TECHNICAL">{t("feedback.form.subjects.technical")}</option>
                          <option value="SUPPORT">{t("feedback.form.subjects.support")}</option>
                          <option value="OTHER">{t("feedback.form.subjects.other")}</option>
                        </Form.Control>
                        <Form.Control.Feedback tooltip type="invalid">
                          {props.errors.subject}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Feedback */}
                    <Col xs={12} className="">
                      <Form.Group controlId="FeedbackFeedback" className="position-relative">
                        <Form.Text className="text-muted">{t("feedback.form.feedback")}</Form.Text>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="feedback"
                          placeholder=""
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.feedback}
                          isInvalid={props.touched.feedback && props.errors.feedback} />
                        <span className="small float-right">{props.values.feedback.length} / 2000</span>
                        <Form.Control.Feedback tooltip type="invalid">
                          {props.errors.feedback}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Waiting to reply */}
                    <Col xs={12} className="">
                      <Form.Group controlId="FeedbackWaitingReply" className="position-relative">
                        <Form.Text className="text-muted">{t("feedback.form.reply")}</Form.Text>
                        <Form.Check
                          type="checkbox"
                          name="waiting_reply"
                          label={t("feedback.form.waiting_reply")}
                          onChange={props.handleChange}
                          value={props.values.waiting_reply}
                          isInvalid={props.touched.waiting_reply && props.errors.waiting_reply}
                          className={props.touched.waiting_reply && props.errors.waiting_reply ? "has-error" : null}
                        />
                        <Form.Control.Feedback tooltip type="invalid">
                          {props.errors.waiting_reply}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="btn-default" onClick={this.handleClose}>
                    {t("guides.button.cancel")}
                  </Button>
                  <Button variant="primary" className="btn-default" type="submit" disabled={isLoading}>
                    {t("guides.button.send")}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.feedback.isLoading,
  destinationId: state.destinations.selectedDestinationId,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  postFeedback: (data) => dispatch(postFeedback(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Feedback));
