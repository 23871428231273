import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "react-bootstrap";

const Paginations = ({ total, active, onSelect }) => {
  const items = [];
  const paginationCount = Math.ceil(total / 5);
  let min = 1;
  let max = paginationCount;
  let moreMin = true;
  let moreMax = true;
  let extraTools = false;

  if (paginationCount > 5) {
    extraTools = true;
    min = active - 2;
    max = active + 2;
    if (min <= 1) {
      min = 1;
      max = 5;
      moreMin = false;
    }
    if (max >= paginationCount) {
      max = paginationCount;
      min = paginationCount - 4;
      moreMax = false;
    }
  }

  for (let number = min; number <= max; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active} onClick={() => onSelect(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="float-md-right">
      <Pagination.First onClick={() => onSelect(1)} disabled={active - 1 === 0} hidden={!extraTools} />
      <Pagination.Prev onClick={() => onSelect(active - 1)} disabled={active - 1 === 0} />
      {moreMin && <Pagination.Ellipsis disabled hidden={!extraTools} className="d-none d-sm-block" />}
      {items}
      {moreMax && <Pagination.Ellipsis disabled hidden={!extraTools} className="d-none d-sm-block" />}
      <Pagination.Next onClick={() => onSelect(active + 1)} disabled={active + 1 > paginationCount} />
      <Pagination.Last
        onClick={() => onSelect(paginationCount)}
        disabled={active + 1 > paginationCount}
        hidden={!extraTools}
      />
    </Pagination>
  );
};

Paginations.propTypes = {
  total: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired
};

Paginations.defaultProps = {};

export default Paginations;
