/* eslint-disable */
import React from "react";
import { Image } from "react-bootstrap";
import { classNames } from "../../../helpers/classNames";

export const GuideImage = ({ data, list, preview, full }) => {
  const classes = classNames({
    "image-container": true,
    "list": list,
    "preview": preview,
    "fullsize": full
  });

  return (
    <div className={classes}>
      <Image src={data.image} className="img-fluid" />
    </div>
  );
};
