import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Login, Recovery, Register } from "./views";
import { login, register } from "../../actions/auth";
import { getAuthToken } from "../../storage/authStorage";
import LanguagePicker from "../../components/languagePicker";

import background1 from "../../assets/login/background-visit-levi.jpg";
import logo from "../../assets/levi_logo.svg";

const registerSuccess = {
  type: "success",
  title: "auth.notifications.register.success.title",
  text: "auth.notifications.register.success.text"
};

const registerFailed = {
  type: "danger",
  title: "auth.notifications.register.failed.title",
  text: "auth.notifications.register.failed.text"
};

const loginFailed = {
  type: "danger",
  title: "auth.notifications.login.failed.title",
  text: "auth.notifications.login.failed.text"
};

class AuthContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: null,
      images: [background1],
      imageIndex: 0,
      values: null
    };
  }

  componentDidMount() {
    const token = getAuthToken();
    if (token) {
      this.props.history.push("/guides");
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  changeBackgroundImage() {
    let currentIndex = 0;
    const { images, imageIndex } = this.state;

    if (imageIndex !== images.length - 1) {
      currentIndex = imageIndex + 1;
    }

    this.setState({ imageIndex: currentIndex });
  }

  onSubmitLogin = (values) => {
    // Determine if we should authenticate or login directly
    if (values.email && values.password) {
      // Authenticate user directly
      this.props
        .login(values.email, values.password)
        .then(() => {
          this.props.history.push("/guides");
        })
        .catch(() => {
          this.setState({ notification: loginFailed });
        });
    }
  };

  onSubmitRegister = (values) => {
    // Determine if we should authenticate or login directly
    if (values && values.email) {
      this.props
        .register(values)
        .then(() => {
          this.props.history.push({ pathname: "/login" });
          this.setState({ notification: registerSuccess, values });
        })
        .catch(() => {
          this.props.history.push({ pathname: "/login" });
          this.setState({ notification: registerFailed });
        });
    }
  };

  closeAlert = () => {
    this.setState({ notification: null });
  };

  render() {
    const { t, isLoading } = this.props;
    const { images, imageIndex } = this.state;

    return (
      <Container fluid className="h-100 align-items-center bg-white auth">
        <LanguagePicker absolute />
        <Row className="h-100">
          <Col xs={0} sm={6} className="d-none d-sm-block background-image">
            {images.map((image, index) => (
              <img key={image} src={image} alt={image} className={imageIndex === index ? "visible" : "hidden"} />
            ))}
          </Col>
          <Col xs={12} sm={6}>
            <Row className="h-100 py-4 align-items-end text-center content">
              <Col xl={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }}>
                <Row className="mb-5">
                  <Col>
                    <img src={logo} alt="Get Tips logo purple" className="logo" />
                    <h2 className="font-weight-bold text-uppercase">{t("auth.application")}</h2>
                    <Switch>
                      <Route path="/login">
                        <h6 className="text-secondary">{t("auth.loginTitle")}</h6>
                      </Route>
                      <Route path="/register">
                        <h6 className="text-secondary">{t("auth.registerTitle")}</h6>
                        <p className="mt-4">{t("auth.registerInfo")}</p>
                      </Route>
                    </Switch>
                  </Col>
                </Row>
                {this.state.notification && (
                  <Container>
                    <Row className="mb-2">
                      <Col>
                        <Alert variant={this.state.notification.type} onClose={this.closeAlert} dismissible>
                          <Alert.Heading>{t(this.state.notification.title)}</Alert.Heading>
                          <p className="mb-0 mr-3">{t(this.state.notification.text)}</p>
                        </Alert>
                      </Col>
                    </Row>
                  </Container>
                )}
                <Switch>
                  <Route path="/login">
                    <Login onSubmit={this.onSubmitLogin} isLoading={isLoading} values={this.state.values} />
                  </Route>
                  <Route path="/register">
                    <Register onSubmit={this.onSubmitRegister} isLoading={isLoading} />
                  </Route>
                  <Route path="/recovery">
                    <Recovery />
                  </Route>
                </Switch>
              </Col>
              <Col xs={12} className="py-3">
                <span className="powered-by">Powered by <a href="https://gettips.fi" target="_blank">Get Tips Oy</a></span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

AuthContainer.propTypes = {
  login: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

AuthContainer.defaultProps = {
  isLoading: false
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, code) => dispatch(login(email, code)),
  register: (values) => dispatch(register(values))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));
