import { postRequest, putRequest, deleteRequest, fileRequest } from "../helpers/fetchHandler";
import {
    CREATE_GUIDE_LOADING,
    CREATE_GUIDE_SUCCESS,
    CREATE_GUIDE_FAILED,
    UPDATE_GUIDE_LOADING,
    UPDATE_GUIDE_SUCCESS,
    UPDATE_GUIDE_FAILED,
    DELETE_GUIDE_LOADING,
    DELETE_GUIDE_SUCCESS,
    DELETE_GUIDE_FAILED,
    // EDIT_GUIDE_SELECTED,
    UPLOAD_IMAGE_LOADING,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILED,
    UPLOAD_VIDEO_LOADING,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAILED,
} from "../constants/actionTypes";

// Require authorization
export function createGuide(data) {
    return dispatch => {
        dispatch({ type: CREATE_GUIDE_LOADING });

        const url = "/api/guides/";
        return postRequest({ url, data, auth: true })
            .then(response => dispatch({ type: CREATE_GUIDE_SUCCESS, payload: response }))
            .catch(error => {
                dispatch({ type: CREATE_GUIDE_FAILED, error });
                return Promise.reject(error);
            });
    }
}

// Require authorization
export function updateGuide(id, data) {
    return dispatch => {
        dispatch({ type: UPDATE_GUIDE_LOADING });

        const url = "/api/guides/" + id + "/";
        return putRequest({ url, data, auth: true })
            .then(response => dispatch({ type: UPDATE_GUIDE_SUCCESS, payload: response }))
            .catch(error => {
                dispatch({ type: UPDATE_GUIDE_FAILED, error });
                return Promise.reject(error);
            });
    }
}

// Require authorization
export function deleteGuide(id) {
    return dispatch => {
        dispatch({ type: DELETE_GUIDE_LOADING });

        const url = "/api/guides/" + id + "/";
        return deleteRequest({ url, auth: true })
            .then(() => dispatch({ type: DELETE_GUIDE_SUCCESS, meta: { id } }))
            .catch(error => {
                dispatch({ type: DELETE_GUIDE_FAILED, error });
                return Promise.reject(error);
            });
    }
}

// Require authorization
function saveImage(image) {
  return (dispatch) => {
    dispatch({ type: UPLOAD_IMAGE_LOADING });

    const data = new FormData();
    data.append("image", image, image.name);

    const url = "/api/imagefileupload";
    return fileRequest({ url, data, auth: true })
      .then(response => dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: response }))
      .catch(error => {
        dispatch({ type: UPLOAD_IMAGE_FAILED, error });
        return Promise.reject(error);
      });
  }
}

// Require authorization
function saveVideo(video) {
  return (dispatch) => {
    dispatch({ type: UPLOAD_VIDEO_LOADING });

    const data = new FormData();
    data.append("video", video, video.name);

    const url = "/api/videofileupload";
    return fileRequest({ url, data, auth: true })
      .then(response => dispatch({ type: UPLOAD_VIDEO_SUCCESS, payload: response }))
      .catch(error => {
        dispatch({ type: UPLOAD_VIDEO_FAILED, error });
        return Promise.reject(error);
      });
  }
}

// Require authorization
export function addImageToGuide(image, guideId) {
  return (dispatch, getState) => {
    const state = getState();

    return dispatch(saveImage(image))
      .then(data => {
        const destination = state.destinations.destinations.find(x => x.id === state.destinations.selectedDestinationId);
        const guide = destination.guides.find(x => x.id === guideId);
        if (!guide) {
          Promise.reject("Missing guide");
        }

        const updatedGuide = Object.assign({}, guide);
        updatedGuide.content.push({
          "image": {
            "id": data.payload.id,
            "image": data.payload.image
          }
        });
        return updatedGuide;
      })
      .then(guide => dispatch(updateGuide(guide.id, guide)))
      .catch(error => Promise.reject(error));
  }
}

// Require authorization
export function addVideoToGuide(video, guideId) {
  return (dispatch, getState) => {
    const state = getState();

    return dispatch(saveVideo(video))
      .then(data => {
        const destination = state.destinations.destinations.find(x => x.id === state.destinations.selectedDestinationId);
        const guide = destination.guides.find(x => x.id === guideId);
        if (!guide) {
          Promise.reject("Missing guide");
        }

        const updatedGuide = Object.assign({}, guide);
        updatedGuide.content.push({
          "video": {
            "id": data.payload.id,
            "video": data.payload.video
          }
        });
        return updatedGuide;
      })
      .then(guide => dispatch(updateGuide(guide.id, guide)))
      .catch(error => Promise.reject(error));
  }
}
