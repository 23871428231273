import React from "react";
import PropTypes from "prop-types";
import { Col, Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import IconPicker from "../../../components/iconPicker";

const validationSchema = (t) => {
  return Yup.object().shape({
    topic_fi: Yup.string()
      .when(["topic_en"], {
        is: topic_en => !topic_en,
        then: Yup.string().required(t("guides.validation.eitherRequired")),
      })
      .max(160, t("guides.validation.invalidMaxTopicLength")),
    topic_en: Yup.string()
      .when(["topic_fi"], {
        is: topic_fi => !topic_fi,
        then: Yup.string().required(t("guides.validation.eitherRequired")),
      })
      .max(160, t("guides.validation.invalidMaxTopicLength")),
    icon: Yup.string()
      .required(t("guides.validation.required")),
  }, 
  [["topic_fi", "topic_en"]]
)}


const GuideModal = ({ data, show, onSubmit, onCancel } ) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Formik
        key={"Formik-edit-guide"}
        enableReinitialize={true}
        initialValues={{
          icon: (data && data.icon) || "body",
          topic_en: (data && data.topic_en) || "",
          topic_fi: (data && data.topic_fi) || "",
        }}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => onSubmit(values)}>
        {({ handleSubmit, ...props }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{ data ? t("guides.modal.title.edit") : t("guides.modal.title.create") }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Row>
              <IconPicker
                type="text"
                name="icon"
                values={props.values}
                handleChange={props.handleChange}
                handleBlur={props.handleBlur}
                isInvalid={props.touched.icon && props.errors.icon}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} controlId="AddNewGuideFormTopicFI" className="position-relative">
                <Form.Text className="text-muted">{t("guides.form.topic_fi")}</Form.Text>
                <Form.Control
                  type="text"
                  name="topic_fi"
                  placeholder=""
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  defaultValue={props.values.topic_fi}
                  isInvalid={props.touched.topic_fi && props.errors.topic_fi} />
                <Form.Control.Feedback tooltip type="invalid">
                  {props.errors.topic_fi}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="AddNewGuideFormTopicEN" className="position-relative">
                <Form.Text className="text-muted">{t("guides.form.topic_en")}</Form.Text>
                <Form.Control
                  type="text"
                  name="topic_en"
                  placeholder=""
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  defaultValue={props.values.topic_en}
                  isInvalid={props.touched.topic_en && props.errors.topic_en} />
                <Form.Control.Feedback tooltip type="invalid">
                  {props.errors.topic_en}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="btn-default" onClick={onCancel}>
                {t("guides.button.cancel")}
              </Button>
              <Button variant="primary" className="btn-default" type="submit">
                {t("guides.button.save")}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
  
GuideModal.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

GuideModal.defaultProps = {
  data: null,
};

export default GuideModal;
