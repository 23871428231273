import { combineReducers } from "redux";

import authReducer from "./auth";
import destinationsReducer from "./destinations";
import feedbackReducer from "./feedback";

const reducers = combineReducers({
  auth: authReducer,
  destinations: destinationsReducer,
  feedback: feedbackReducer
});

export default reducers;
