import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Recovery = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="mb-5">
        <Col>
          <p>{t("auth.WIP")}</p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col lg={{ span: 6, offset: 3 }} xs={12}>
          <Button block variant="primary" as={Link} to="/login">
            {t("auth.button.backToLogin")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

Recovery.propTypes = {};
Recovery.defaultProps = {};

export default Recovery;
