import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button, Form, Toast } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { withTranslation, Trans } from "react-i18next";

import { updateDestination } from "../../actions/destinations";
import { capitalize } from "../../helpers/capitalize";

const validationSchema = (t) => {
  return Yup.object().shape({
    name_en: Yup.string()
      .when(["name_fi"], {
        is: (name_fi) => !name_fi,
        then: Yup.string().required(t("settings.validation.eitherRequired"))
      })
      .min(3, t("settings.validation.invalidMinLength"))
      .max(120, t("settings.validation.invalidMaxLength")),
    name_fi: Yup.string()
      .when(["name_en"], {
        is: (name_en) => !name_en,
        then: Yup.string().required(t("settings.validation.eitherRequired"))
      })
      .min(3, t("settings.validation.invalidMinLength"))
      .max(120, t("settings.validation.invalidMaxLength")),
    address: Yup.string()
      .min(3, t("settings.validation.invalidMinLength"))
      .max(120, t("settings.validation.invalidMaxLength"))
      .required(t("settings.validation.required")),
    phone: Yup.string()
      .min(6, t("settings.validation.invalidMinLength"))
      .max(30, t("settings.validation.invalidMaxLength"))
      .matches(/^\+(?:[0-9]?){6,14}[0-9]$/, t("settings.validation.invalidPhone")),
    description_en: Yup.string().when(["description_fi"], {
      is: (description_fi) => !description_fi,
      then: Yup.string().required(t("settings.validation.eitherRequired"))
    }),
    description_fi: Yup.string().when(["description_en"], {
      is: (description_en) => !description_en,
      then: Yup.string().required(t("settings.validation.eitherRequired"))
    })
  },[["name_fi", "name_en"],["description_fi", "description_en"]]);
};

const FormContent = ({ t, props }) => (
  <Col>
    <Form.Row>
      <Form.Group as={Col} xs={12} xl={6} controlId="settingsNameFI" className="position-relative">
        <Form.Text className="text-muted">{t("settings.form.name_fi")}</Form.Text>
        <Form.Control
          type="text"
          name="name_fi"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.name_fi}
          isInvalid={props.touched.name_fi && props.errors.name_fi}
          className={props.touched.name_fi && props.errors.name_fi ? "has-error" : null}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.name_fi}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={12} xl={6} controlId="settingsNameEN" className="position-relative">
        <Form.Text className="text-muted">{t("settings.form.name_en")}</Form.Text>
        <Form.Control
          type="text"
          name="name_en"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.name_en}
          isInvalid={props.touched.name_en && props.errors.name_en}
          className={props.touched.name_en && props.errors.name_en ? "has-error" : null}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.name_en}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={12} controlId="settingsAddress" className="position-relative">
        <Form.Text className="text-muted">{t("settings.form.address")}</Form.Text>
        <Form.Control
          type="text"
          name="address"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.address}
          isInvalid={props.touched.address && props.errors.address}
          className={props.touched.address && props.errors.address ? "has-error" : null}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.address}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={12} lg={6} controlId="settingsPhone" className="position-relative">
        <Form.Text className="text-muted">{t("settings.form.phone")}</Form.Text>
        <Form.Control
          type="phone"
          name="phone"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.phone}
          isInvalid={props.touched.phone && props.errors.phone}
          className={props.touched.phone && props.errors.phone ? "has-error" : null}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={12} lg={6} controlId="settingsAccessCode" className="position-relative">
        <Form.Text className="text-muted">{t("settings.form.access_code")}</Form.Text>
        <Form.Control
          disabled
          type="text"
          name="access_code"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.access_code}
          isInvalid={props.touched.access_code && props.errors.access_code}
          className={props.touched.access_code && props.errors.access_code ? "has-error" : null}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
    {props.dirty}
  </Col>
);

const EditDestinationForm = ({ t, initialValues, language, onSave, isLoading }) => (
  <Formik
    enableReinitialize={true}
    initialValues={initialValues}
    validationSchema={validationSchema(t)}
    validateOnBlur={false}
    onSubmit={(values, actions) => onSave(values, actions)}>
    {({ handleSubmit, handleReset, ...props }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Row>
          <FormContent t={t} props={props} language={language} />
        </Row>
        {props.dirty && (
          <div className="fixed-footer-bar">
            <div className="float-left">
              <span className="text-dark align-middle">{t("common.unsavedChanges.content")}</span>
            </div>
            <div className="float-right">
              <Button variant="link" onClick={handleReset} className="mr-4" type="reset" disabled={isLoading}>
                {t("common.unsavedChanges.button.cancel")}
              </Button>
              <Button variant="primary" className="btn-default-lg" type="submit" disabled={isLoading}>
                {t("common.unsavedChanges.button.save")}
              </Button>
            </div>
          </div>
        )}
      </Form>
    )}
  </Formik>
);

class settingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: { display: false, type: null, content: null },
      language: "en",
      form: {
        access_code: props.destination?.access_code || "",
        name_en: props.destination?.name_en || "",
        name_fi: props.destination?.name_fi || "",
        address: props.destination?.address || "",
        country: props.destination?.country || "",
        municipal: props.destination?.municipal || "",
        phone: props.destination?.phone || "",
        description_en: props.destination?.description_en || "",
        description_fi: props.destination?.description_fi || ""
      }
    };
  }

  setToast = (display, type, message, values) => {
    this.setState({ toast: { display, type, content: { message, values } } });
  };

  onSave = (values, actions) => {
    const { selectedDestinationId } = this.props;
    this.props
      .updateDestination(selectedDestinationId, {
        name: values.name_en || values.name_fi,
        name_en: values.name_en,
        name_fi: values.name_fi,
        description: values.description_en || values.description_fi,
        description_en: values.description_en,
        description_fi: values.description_fi,
        municipal: values.municipal,
        address: values.address,
        phone: values.phone
      })
      .then(() => actions.setSubmitting(false))
      .then(() => actions.resetForm({ values }))
      .then(() => this.setToast(true, "success", "save.success"))
      .catch(() => this.setToast(true, "error", "save.failed"));
  };

  render() {
    const { toast, language, form } = this.state;
    const { t } = this.props;

    return (
      <Container fluid>
        {toast && toast.display && (
          <Toast
            className={"fixed-toast " + toast.type}
            onClose={() => this.setToast(false)}
            show={toast.display}
            delay={5000}
            autohide>
            <Toast.Header>
              <strong className="mr-auto">{t("common.notifications.type." + toast.type)}</strong>
            </Toast.Header>
            <Toast.Body>{t("settings.notifications." + toast.content.message, toast.content.values)}</Toast.Body>
          </Toast>
        )}
        <Row>
          <Col>
            <h3 className="mb-4 font-weight-bold">{t("settings.title")}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="flex-row flex-wrap mb-4">
              <Card.Body>
                <Row>
                  <Col xs={12} sm={8} className="br-1">
                    <div className="p-sm-4">
                      <Row className="mb-3">
                        <Col>
                          <h5>{t("settings.subtitle")}</h5>
                        </Col>
                      </Row>
                      <EditDestinationForm
                        t={t}
                        initialValues={form}
                        language={language}
                        onSave={(values, actions) => this.onSave(values, actions)}
                        isLoading={this.props.isLoading}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={4}>
                    <div className="p-sm-4 small h-100">
                      <h5 className="mb-4">{t("settings.instruction.title")}</h5>
                      <Trans i18nKey="settings.instruction.content" components={{ p: <p />, b: <b /> }} />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <span className="small float-right mb-4 text-uppercase">
              {t("common.version", {
                initials: process.env.REACT_APP_INITIALS,
                env: process.env.NODE_ENV,
                version: process.env.REACT_APP_VERSION,
                build: process.env.REACT_APP_BUILD
              })}
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}

settingsContainer.propTypes = {
  updateDestination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

settingsContainer.defaultProps = {
  isLoading: false
};

const mapStateToProps = (state) => ({
  isLoading: state.destinations.isLoading,
  selectedDestinationId: state.destinations.selectedDestinationId,
  destination: state.destinations.destinations.find((x) => x.id === state.destinations.selectedDestinationId)
});

const mapDispatchToProps = (dispatch) => ({
  updateDestination: (id, values) => dispatch(updateDestination(id, values))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(settingsContainer));
