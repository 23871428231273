/* eslint-disable */
import React from "react";
import { Player, BigPlayButton, ControlBar } from "video-react";
import { classNames } from "../../../helpers/classNames";

export const GuideVideo = ({ data, list, preview, full }) => {
  const classes = classNames({
    "video-container": true,
    "list": list,
    "preview": preview,
    "fullsize": full
  });

  return (
    <div className={classes}>
      <Player src={data.video} className="video-player" height={400} fluid={list ? true : false}>
        <BigPlayButton position="center" />
        <ControlBar disableCompletely={list} />
      </Player>
    </div>
  );
};
