import {
  LOG_OUT,

  DESTINATIONS_LOADING,
  DESTINATIONS_SUCCESS,
  DESTINATIONS_FAILED,

  CREATE_DESTINATION_LOADING,
  CREATE_DESTINATION_SUCCESS,
  CREATE_DESTINATION_FAILED,

  UPDATE_DESTINATION_LOADING,
  UPDATE_DESTINATION_SUCCESS,
  UPDATE_DESTINATION_FAILED,

  DELETE_DESTINATION_LOADING,
  DELETE_DESTINATION_SUCCESS,
  DELETE_DESTINATION_FAILED,

  DESTINATION_SELECTED,
  DESTINATION_UNSELECTED,

  CREATE_GUIDE_LOADING,
  CREATE_GUIDE_SUCCESS,
  CREATE_GUIDE_FAILED,

  UPDATE_GUIDE_LOADING,
  UPDATE_GUIDE_SUCCESS,
  UPDATE_GUIDE_FAILED,

  DELETE_GUIDE_LOADING,
  DELETE_GUIDE_SUCCESS,
  DELETE_GUIDE_FAILED,

  UPLOAD_IMAGE_LOADING,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILED,

  UPLOAD_VIDEO_LOADING,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILED,

  EXAMPLE_DESTINATION_LOADING,
  EXAMPLE_DESTINATION_SUCCESS,
  EXAMPLE_DESTINATION_FAILED,
} from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  destinations: [],
  selectedDestinationId: null
};

function updateDestination(destinations, destination) {
  return [...destinations.map((data) => (data.id === destination.id ? destination : data))];
}

function deleteDestination(destinations, id) {
  return [...destinations.filter((data) => data.id !== id)];
}

function createGuide(destinations, selectedDestinationId, guide) {
  const destination = destinations.find(x => x.id === selectedDestinationId);
  const updatedGuides = [...destination.guides, guide];
  const updatedDestination = { ...destination, guides: updatedGuides };

  return [...destinations.map((data) => (data.id === updatedDestination.id ? updatedDestination : data))];
}

function updateGuide(destinations, selectedDestinationId, guide) {
  const destination = destinations.find(x => x.id === selectedDestinationId);
  const updatedGuides = destination.guides.map(data => (data.id === guide.id ? guide : data))
  const updatedDestination = { ...destination, guides: updatedGuides };

  return [...destinations.map((data) => (data.id === updatedDestination.id ? updatedDestination : data))];
}

function deleteGuide(destinations, selectedDestinationId, id) {
  const destination = destinations.find(x => x.id === selectedDestinationId);
  const updatedGuides = destination.guides.filter(data => (data.id !== id))
  const updatedDestination = { ...destination, guides: updatedGuides };

  return [...destinations.map((data) => (data.id === updatedDestination.id ? updatedDestination : data))];
}

export default function(state: any = initialState, action) {
  switch (action.type) {
    // =============================================================================
    case DESTINATIONS_LOADING:
      return { ...state, isLoading: true };
    case DESTINATIONS_SUCCESS:
      return { ...state, isLoading: false, destinations: action.payload };
    case DESTINATIONS_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case DESTINATION_SELECTED:
      return { ...state, selectedDestinationId: action.payload };
    case DESTINATION_UNSELECTED:
      return { ...state, selectedDestinationId: null };
    // =============================================================================
    case CREATE_DESTINATION_LOADING:
      return { ...state, isLoading: true };
    case CREATE_DESTINATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        destinations: [...state.destinations, action.payload],
        selectedDestinationId: action.payload.id
      };
    case CREATE_DESTINATION_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case UPDATE_DESTINATION_LOADING:
      return { ...state, isLoading: true };
    case UPDATE_DESTINATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        destinations: updateDestination(state.destinations, action.payload)
      };
    case UPDATE_DESTINATION_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case DELETE_DESTINATION_LOADING:
      return { ...state, isLoading: true };
    case DELETE_DESTINATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        destinations: deleteDestination(state.destinations, action.payload)
      };
    case DELETE_DESTINATION_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case CREATE_GUIDE_LOADING:
      return { ...state, isLoading: true };
    case CREATE_GUIDE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        destinations: createGuide(state.destinations, state.selectedDestinationId, action.payload),
      };
    case CREATE_GUIDE_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case UPDATE_GUIDE_LOADING:
      return { ...state, isLoading: true };
    case UPDATE_GUIDE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        destinations: updateGuide(state.destinations, state.selectedDestinationId, action.payload),
      };
    case UPDATE_GUIDE_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case DELETE_GUIDE_LOADING:
        return { ...state, isLoading: true };
    case DELETE_GUIDE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        destinations: deleteGuide(state.destinations, state.selectedDestinationId, action.meta.id),
      };
    case DELETE_GUIDE_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case UPLOAD_IMAGE_LOADING:
        return { ...state, isLoading: true };
    case UPLOAD_IMAGE_SUCCESS:
      return { ...state, isLoading: false };
    case UPLOAD_IMAGE_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case UPLOAD_VIDEO_LOADING:
      return { ...state, isLoading: true };
    case UPLOAD_VIDEO_SUCCESS:
      return { ...state, isLoading: false };
    case UPLOAD_VIDEO_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case EXAMPLE_DESTINATION_LOADING:
      return { ...state, isLoading: true };
    case EXAMPLE_DESTINATION_SUCCESS:
      return { ...state, example: action.payload, isLoading: false };
    case EXAMPLE_DESTINATION_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case LOG_OUT:
      return initialState;
    // =============================================================================
    default:
      break;
  }
  return state;
}
