import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiUserCircle } from "react-icons/hi";

import LanguagePicker from "./languagePicker";
import Feedback from "./feedback";

const Header = ({ logout, user }) => {
  const { t } = useTranslation();

  return (
    <Navbar fixed="top" bg="white" className="header-navigation">
      <Feedback />
      <NavDropdown title={<HiUserCircle />} id="user-details-dropdown" alignRight className="bl-2">
        <NavDropdown.Header>{(user && user.email) || ""}</NavDropdown.Header>
        <NavDropdown.Item onClick={logout}>{t("header.logout")}</NavDropdown.Item>
      </NavDropdown>
      <LanguagePicker className="bl-2" />
    </Navbar>
  );
};

Header.propTypes = {};
Header.defaultProps = {};

export default Header;
