export const getLocaleValue = (content, value, lang) => {
  if (!content) {
    return null;
  } else if (lang && content[`${value}_${lang}`]) {
    return content[`${value}_${lang}`];
  } else if (lang && content[`${value}-${lang}`]) {
    return content[`${value}-${lang}`];
  }
  return content[value];
}
