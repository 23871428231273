import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import { HiCheck } from "react-icons/hi";
import * as Yup from "yup";
import { withTranslation, Trans } from "react-i18next";

import { createDestination } from "../../../actions/destinations";

const validationSchema = (t) => {
  return Yup.object().shape({
    name_fi: Yup.string()
      .when(["name_en"], {
        is: name_en => !name_en,
        then: Yup.string().required(t("destinations.create.validation.eitherRequired"))
      })
      .min(3, t("destinations.create.validation.invalidMinLength"))
      .max(120, t("destinations.create.validation.invalidMaxLength")),
    name_en: Yup.string()
      .when(["name_fi"], {
        is: name_fi => !name_fi,
        then: Yup.string().required(t("destinations.create.validation.eitherRequired"))
      })
      .min(3, t("destinations.create.validation.invalidMinLength"))
      .max(120, t("destinations.create.validation.invalidMaxLength")),
    address: Yup.string()
      .min(3, t("destinations.create.validation.invalidMinLength"))
      .max(120, t("destinations.create.validation.invalidMaxLength"))
      .required(t("destinations.create.validation.required")),
    municipal: Yup.string()
      .min(3, t("destinations.create.validation.invalidMinLength"))
      .max(30, t("destinations.create.validation.invalidMaxLength"))
      .required(t("destinations.create.validation.required")),
    country: Yup.string()
      .min(3, t("destinations.create.validation.invalidMinLength"))
      .max(30, t("destinations.create.validation.invalidMaxLength"))
      .required(t("destinations.create.validation.required")),
    phone: Yup.string()
      .min(6, t("destinations.create.validation.invalidMinLength"))
      .max(30, t("destinations.create.validation.invalidMaxLength"))
      .matches(/^\+(?:[0-9]?){6,14}[0-9]$/, t("settings.validation.invalidPhone")),
  }, 
  [["name_fi", "name_en"]]
)};

const validationSchema2 = (t) => {
  return Yup.object().shape({
    description_fi: Yup.string()
      .when(["description_en"], {
        is: description_en => !description_en,
        then: Yup.string().required(t("destinations.create.validation.eitherRequired"))
      }),
    description_en: Yup.string()
      .when(["description_fi"], {
        is: description_fi => !description_fi,
        then: Yup.string().required(t("destinations.create.validation.eitherRequired"))
      })
  }, 
  [["description_fi", "description_en"]]
)};

const Form1 = ({ props, t }) => (
  <Col>
    <Form.Group controlId="CreateDestinationFormNameFI" className="position-relative">
      <Form.Control
        autoFocus
        type="text"
        name="name_fi"
        placeholder={t("destinations.create.form.name_fi")}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        defaultValue={props.values.name_fi}
        isInvalid={props.touched.name_fi && props.errors.name_fi}
        className={props.touched.name_fi && props.errors.name_fi ? "has-error" : null}
      />
      <Form.Control.Feedback tooltip type="invalid">
        {props.errors.name_fi}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="CreateDestinationFormNameEN" className="position-relative">
      <Form.Control
        type="text"
        name="name_en"
        placeholder={t("destinations.create.form.name_en")}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        defaultValue={props.values.name_en}
        isInvalid={props.touched.name_en && props.errors.name_en}
        className={props.touched.name_en && props.errors.name_en ? "has-error" : null}
      />
      <Form.Control.Feedback tooltip type="invalid">
        {props.errors.name_en}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="CreateDestinationFormAddress" className="position-relative">
      <Form.Control
        type="text"
        name="address"
        placeholder={t("destinations.create.form.address")}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        defaultValue={props.values.address}
        isInvalid={props.touched.address && props.errors.address}
        className={props.touched.address && props.errors.address ? "has-error" : null}
      />
      <Form.Control.Feedback tooltip type="invalid">
        {props.errors.address}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Row>
      <Form.Group as={Col} xs={12} lg={6} controlId="CreateDestinationFormMunicipal" className="position-relative">
        <Form.Control
          type="text"
          name="municipal"
          placeholder={t("destinations.create.form.city")}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          defaultValue={props.values.municipal}
          isInvalid={props.touched.municipal && props.errors.municipal} />
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.municipal}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={12} lg={6} controlId="CreateDestinationFormCountry" className="position-relative">
        <Form.Control
          custom
          as="select"
          name="country"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          defaultValue={props.values.country}
          isInvalid={props.touched.country && props.errors.country}
          className={props.touched.country && props.errors.country ? "has-error" : null}>
          <option value="" disabled hidden>{t("destinations.create.form.country")}</option>
          <option value="Finland">Finland</option>
        </Form.Control>
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.country}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
    <Form.Row>
      <Form.Group as={Col} xs={12} lg={6} controlId="CreateDestinationFormPhone" className="position-relative">
        <Form.Control
          type="phone"
          name="phone"
          placeholder={t("destinations.create.form.phone")}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          defaultValue={props.values.phone}
          isInvalid={props.touched.phone && props.errors.phone}
          className={props.touched.phone && props.errors.phone ? "has-error" : null}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {props.errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  </Col>
);

const Form2 = ({ props, t }) => (
  <Col>
    <Row>
      <Col>
        <Form.Group controlId="CreateDestinationFormDescriptionFI" className="position-relative">
          <Form.Control
            autoFocus
            as="textarea"
            rows={6}
            name="description_fi"
            placeholder={t("destinations.create.form.description_fi")}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            defaultValue={props.values.description_fi}
            isInvalid={props.touched.description_fi && props.errors.description_fi}
            className={props.touched.description_fi && props.errors.description_fi ? "has-error" : null}
          />
          <Form.Control.Feedback tooltip type="invalid">
            { props.errors.description_fi }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="CreateDestinationFormDescriptionEN" className="position-relative">
          <Form.Control
            as="textarea"
            rows={6}
            name="description_en"
            placeholder={t("destinations.create.form.description_en")}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            defaultValue={props.values.description_en}
            isInvalid={props.touched.description_en && props.errors.description_en}
            className={props.touched.description_en && props.errors.description_en ? "has-error" : null}
          />
          <Form.Control.Feedback tooltip type="invalid">
            { props.errors.description_en }
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  </Col>
);

const CreateDestinationForm = ({ form, phase, onNext, onPrevious, isLoading, t }) => (
  <Formik
    initialValues={form}
    validationSchema={phase === 1 ? validationSchema(t) : validationSchema2(t)}
    validateOnBlur={false}
    onSubmit={(values) => onNext(values)}>
    {({ handleSubmit, ...props }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Row className="mb-4">
          {(() => {
            switch (phase) {
              case 1:
                return <Form1 props={props} t={t} />;
              case 2:
                return <Form2 props={props} t={t} />;
              default:
                return null;
            }
          })()}
        </Row>
        <Row>
          <Col xs={12} md={{ span: 6 }} className="mb-4">
            <Button
              variant="primary"
              className="btn-default-lg btn-block-xs-only"
              onClick={onPrevious}
              hidden={phase === 1} disabled={isLoading}>
              {t("destinations.create.button.previous")}
            </Button>
          </Col>
          <Col xs={12} md={{ span: 6 }} className="mb-4">
            <Button
              variant="primary"
              className="btn-default-lg float-right btn-block-xs-only"
              type="submit"
              disabled={isLoading}>
              { phase === 2 ? t("destinations.create.button.save") : t("destinations.create.button.next") }
            </Button>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);

class CreateDestinationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: 1,
      language: "fi",
      form: { name_en: "", address: "", country: "Finland", municipal: "", phone: "", description_en: "" }
    };
  }

  onNext = (values) => {
    const { phase } = this.state;

    if (phase === 2) {
      this.props
        .createDestination({
          name: values.name_en || values.name_fi,
          name_fi: values.name_fi,
          name_en: values.name_en,
          description: values.description_en || values.description_fi,
          description_fi: values.description_fi,
          description_en: values.description_en,
          municipal: values.municipal,
          address: values.address,
          phone: values.phone
        })
        .then((data) => {
          this.props.history.push("/");
        })
        .catch(() => {});
    } else {
      this.setState({ phase: phase + 1 });
    }
  };

  onPrevious = () => {
    const { phase } = this.state;
    if (phase !== 1) {
      this.setState({ phase: phase - 1 });
    }
  };

  render() {
    const { t } = this.props;
    const { phase, language, form } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <h3 className="mb-4 font-weight-bold">{t("destinations.create.title")}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="flex-row flex-wrap mb-4">
              <Card.Body>
                <Row>
                  <Col xs={12} xl={8} className="br-1">
                    <div className="p-sm-4">
                      <Row className="mb-4">
                        <Col>
                          <h4>{t("destinations.create.subtitle.phase_" + phase)}</h4>
                        </Col>
                      </Row>
                      <CreateDestinationForm
                        t={t}
                        form={form}
                        language={language}
                        phase={phase}
                        onPrevious={() => this.onPrevious()}
                        onNext={(val) => this.onNext(val)}
                        isLoading={this.props.isLoading}
                      />
                    </div>
                  </Col>
                  <Col xs={12} xl={4}>
                    <div className="p-sm-4 small h-100">
                      <h5 className="mb-4">{t("destinations.create.instruction.title")}</h5>
                      <Trans
                        i18nKey="destinations.create.instruction.content"
                        components={{ p: <p />, b: <b /> }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-center">
              <div className={"progress-btn" + (phase > 0 ? " active" : "")}>
                <div className="progress-btn-circle">
                  { phase > 1 ? <HiCheck /> : <span>1</span> }
                </div>
                <p className="progress-btn-text">{t("destinations.create.progress.phase_1")}</p>
              </div>
              <span className={"progress-separator" + (phase > 2 ? " active" : "")} />
              <div className={"progress-btn" + (phase > 1 ? " active" : "")}>
                <div className="progress-btn-circle">
                  { phase > 2 ? <HiCheck /> : <span>2</span> }
                </div>
                <p className="progress-btn-text">{t("destinations.create.progress.phase_2")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

CreateDestinationContainer.propTypes = {
  createDestination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

CreateDestinationContainer.defaultProps = {
  isLoading: false
};

const mapStateToProps = (state) => ({
  isLoading: state.destinations.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  createDestination: (values) => dispatch(createDestination(values))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateDestinationContainer));
