/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiChevronDown, HiPlus } from "react-icons/hi";
import { getLocaleValue } from "../helpers/getLocaleValue";

const CustomToggle = React.forwardRef(({ onClick, selectedDestination, lang }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    <div className="dest-container">
      <div className="left">
        <p className="title">{getLocaleValue(selectedDestination, "name", lang)}</p>
        <span className="subtitle">{selectedDestination.access_code}</span>
      </div>
      <div className="right">
        <HiChevronDown />
      </div>
    </div>
  </a>
));

const DestinationPicker = ({ destinations, selectedDestination, selectDestination }) => {
  if (destinations && selectedDestination) {
    const { t, i18n } = useTranslation();

    return (
      <Dropdown drop="right" className="nav-dest-selection">
        <Dropdown.Toggle
          as={CustomToggle}
          selectedDestination={selectedDestination}
          lang={i18n.language}
          id="dest-dropdown"
        />
        <Dropdown.Menu>
          <Dropdown.Header>{t("header.brand")}</Dropdown.Header>
          {destinations.slice(0, 10).map((destination, index) => (
            <Dropdown.Item key={index} onClick={() => selectDestination(destination)}>
              {getLocaleValue(destination, "name", i18n.language)}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/destinations" className="text-primary">
            {t("header.viewAllDestinations", { length: destinations.length })}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return <span className="nav-spacer" />;
};

DestinationPicker.propTypes = {};
DestinationPicker.defaultProps = {};

export default DestinationPicker;
