import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";

const validationSchema = (t) => {
  return Yup.object({
    email: Yup.string()
      .email(t("auth.validation.invalidEmail"))
      .required(t("auth.validation.required")),
    password: Yup.string()
      .min(9, t("auth.validation.invalidPasswordLength"))
      .required(t("auth.validation.required"))
  })
};

const Login = ({ onSubmit, isLoading, values }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: values?.email || "", password: "" }}
        validationSchema={validationSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(submitValues) => onSubmit(submitValues)}>
        {({ errors, touched, handleChange, handleBlur, handleSubmit, ...props }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-5">
              <Col>
                <Form.Group controlId="LoginFormEmail" className="position-relative">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder={t("auth.form.email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.email}
                    isInvalid={touched.email && errors.email}
                    disabled={!!values?.email}
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="LoginFormPassword" className="position-relative">
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder={t("auth.form.password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.password}
                    isInvalid={touched.password && errors.password}
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Row>
                  <Col className="text-right small underline">
                    <Link to="/recovery">{t("auth.button.forgotPassword")}</Link>
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={{ span: 8, offset: 2 }} xs={12}>
                <Button block variant="primary" type="submit" disabled={isLoading}>
                  {t("auth.button.login")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

Login.defaultProps = {};

export default Login;
