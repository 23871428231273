// List is using ionicons v.5.0.1
// Project using ionicons v.4.5.6

const guideIcons = [
    { icon: "body" },
    { icon: "home" },
    { icon: "bonfire" },
    { icon: "car" },
    { icon: "card" },
    { icon: "wifi" },
    { icon: "bicycle" },
    { icon: "shirt" },
    { icon: "heart" },
    { icon: "pizza" },
    // { icon: "lock-open" },
    // { icon: "lock-closed" },
    { icon: "basket" },
    { icon: "map" },
    { icon: "hammer" },
    { icon: "bug" },
    { icon: "cart" },
    // { icon: "barbell" },
    { icon: "archive" },
    { icon: "bed" },
    { icon: "alarm" },
    { icon: "attach" },
    // { icon: "battery-charging" },
    { icon: "build" },
    { icon: "bulb" },
    { icon: "bus" },
    { icon: "cafe" },
    { icon: "call" },
    // { icon: "chatbox-ellipses" },
    { icon: "construct" },
    { icon: "desktop" },
    // { icon: "earth" },
    { icon: "flashlight" },
    { icon: "female" },
    { icon: "male" },
    // { icon: "male-female" },
    // { icon: "game-controller" },
    { icon: "glasses" },
    // { icon: "golf" },
    { icon: "happy" },
    { icon: "headset" },
    { icon: "hourglass" },
    { icon: "key" },
    // { icon: "language" },
    // { icon: "library" },
    { icon: "moon" },
    { icon: "sunny" },
    { icon: "thunderstorm" },
    { icon: "snow" },
    { icon: "thermometer" },
    { icon: "paw" },
    { icon: "people" },
    // { icon: "person-add" },
    // { icon: "person-remove" },
    { icon: "power" },
    { icon: "print" },
    { icon: "ribbon" },
    // { icon: "shield-checkmark" },
    // { icon: "thumbs-up" },
    // { icon: "thumbs-down" },
    { icon: "rose" },
    // { icon: "trail-sign" },
    { icon: "trash" },
    // { icon: "musical-notes" },
    { icon: "notifications" },
    // { icon: "volume-high" },
    // { icon: "volume-mute" },
    { icon: "warning" },
    // { icon: "logo-facebook" },
    // { icon: "logo-google" },
    // { icon: "logo-twitter" },
    // { icon: "logo-instagram" },
    // { icon: "logo-reddit" },
    // { icon: "logo-vk" },
    // { icon: "logo-skype" },
];

export default guideIcons;
