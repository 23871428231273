import React from "react";
import { Container, Card } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { HiOutlineEmojiSad } from "react-icons/hi";

function ErrorContainer(props) {
  return (
    <Container fluid>
      <Card className="text-center p-4 mt-4">
        <Card.Body>
          <h4 className="mb-4 font-weight-bold">{props.t("error.404.title")}</h4>
          <p>{props.t("error.404.content")} <HiOutlineEmojiSad className="text-primary" /></p>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default withTranslation()(ErrorContainer);
