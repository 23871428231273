import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ show, title, content, onOK, onCancel, danger }) => {
  const { t } = useTranslation();

  return (
    <Modal centered show={show} onHide={onCancel} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t("common.confirmation.title." + title)}</Modal.Title>
      </Modal.Header>
      { content && (
        <Modal.Body>{t("common.confirmation.content." + content)}</Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="secondary" className="btn-default-sm" onClick={onCancel}>
          {t("common.confirmation.button.cancel")}
        </Button>
        <Button variant={danger ? "danger" : "primary"} className="btn-default-sm" onClick={onOK}>
          {t("common.confirmation.button.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
  
ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  onOK: PropTypes.func,
  onCancel: PropTypes.func,
  danger: PropTypes.bool
};

ConfirmationModal.defaultProps = {
  content: null,
  onOK: () => {},
  onCancel: () => {},
  danger: false
};

export default ConfirmationModal;
