import { getAuthToken } from "../storage/authStorage";
import i18n from "i18next";
import axios from "axios";

async function getHeaders(authRequired, headers) {
  const configuredHeaders = headers || {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": i18n.language || "en",
  };

  if (authRequired) {
    const token = getAuthToken();
    configuredHeaders.Authorization = `Token ${token}`;
  }

  return configuredHeaders;
}

function responseHandler(response) {
  if (response.ok) {
    if (response.status === 204) {
      return "";
    }
    return response.json();
  }
  return response.json().then((error) => Promise.reject(error));
}

export async function getRequest({ url, auth }) {
  return fetch(process.env.REACT_APP_API_URL + url, {
    method: "GET",
    headers: await getHeaders(auth || false)
  }).then(responseHandler);
}

export async function postRequest({ url, data, auth }) {
  return fetch(process.env.REACT_APP_API_URL + url, {
    method: "POST",
    headers: await getHeaders(auth || false),
    body: JSON.stringify(data)
  }).then(responseHandler);
}

export async function putRequest({ url, data, auth }) {
  return fetch(process.env.REACT_APP_API_URL + url, {
    method: "PUT",
    headers: await getHeaders(auth || false),
    body: JSON.stringify(data)
  }).then(responseHandler);
}

export async function deleteRequest({ url, auth }) {
  return fetch(process.env.REACT_APP_API_URL + url, {
    method: "DELETE",
    headers: await getHeaders(auth || false)
  }).then(responseHandler);
}

export async function fileRequest({ url, data, auth }) {
  const headers = { "Content-Type": "multipart/form-data" };

  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + url,
    data: data,
    headers: await getHeaders(auth, headers),
  })
  .then(response => response.data)
  .catch(error => Promise.reject(error.response));
}
