import {
  LOG_OUT,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAILED
} from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  user: null
};

export default function(state: any = initialState, action) {
  switch (action.type) {
    // =============================================================================
    case REGISTER_LOADING:
      return { ...state, isLoading: true };
    case REGISTER_SUCCESS:
      return { ...state, isLoading: false, user: { ...state.user, email: action.meta.email } };
    case REGISTER_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case LOGIN_LOADING:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
      return { ...state, isLoading: false, user: { ...state.user, email: action.meta.email } };
    case LOGIN_FAILED:
      return { ...state, isLoading: false };
    // =============================================================================
    case LOG_OUT:
      return initialState;
    // =============================================================================
    default:
      break;
  }
  return state;
}
