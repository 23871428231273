import React from "react";
import { Container, Card, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

function MissingDestinationContainer(props) {
  return (
    <Container fluid>
      <Card className="text-center p-4 mt-4">
        <Card.Body>
          <h4 className="mb-4 font-weight-bold">{props.t("error.missingDestination.title")}</h4>
          <p>{props.t("error.missingDestination.content")}</p>
          <Button 
            variant="primary"
            className="btn-default-lg mt-4"
            onClick={() => props.history.push("/destinations/create")}>
            {props.t("error.missingDestination.button")}
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default withTranslation()(MissingDestinationContainer);
