import React from "react";
import * as FontIcon from "react-icons/io";

export const IonIcon = ({ name, style, className }) => {
  let iconName = "IoIos" + name[0].toUpperCase() + name.substring(1);
  const Icon = FontIcon[iconName];
  if (Icon) {
    return <Icon className={ className || "icon" } style={style} />;
  }
  return <div />;
}
