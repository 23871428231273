import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiDocumentDuplicate, HiPencilAlt, HiTrash } from "react-icons/hi";
import { getLocaleValue } from "../../../../helpers/getLocaleValue";

import placeholder from "../../../../assets/placeholder-home.jpg";

const Destination = ({ destination, onSelect, onDelete, onCopyToClipboard }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <Card className="flex-row flex-wrap destination">
      <Card.Body className="p-0">
        <Row>
          <Col style={{ maxWidth: "200px" }} className="d-none d-xl-block">
            <img src={placeholder} alt="destination placeholder" />
          </Col>
          <Col>
            <div className="p-4">
              <Card.Title className="mb-2 font-weight-bold destination-title">
                {getLocaleValue(destination, "name", language)}
              </Card.Title>
              <Row className="destination-text">
                <Card.Text as={Col} className="mb-2">
                  <span className="text-capitalize">
                    {destination.address}
                    <br />
                    {destination.municipal}
                  </span>
                  <span className="text-uppercase">, Finland</span>
                </Card.Text>
                {destination.phone && (
                  <Card.Text as={Col} className="mb-2">
                    {destination.phone}
                  </Card.Text>
                )}
              </Row>
              <Card.Text className="destination-description limit-text-3">
                {getLocaleValue(destination, "description", language)}
              </Card.Text>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="p-4 d-flex align-items-end flex-column h-100">
              <p
                className="text-center w-100 destination-access-code cursor-pointer"
                onClick={() => onCopyToClipboard(destination.access_code)}>
                <HiDocumentDuplicate className="icon mr-2" />
                {destination.access_code}
              </p>
              <div className="w-100">
                <Button variant="link" className="text-left text-secondary" onClick={() => onSelect(destination)}>
                  <HiPencilAlt className="icon mr-2" />
                  {t("destinations.all.button.edit")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

Destination.propTypes = {
  destination: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    municipal: PropTypes.string,
    phone: PropTypes.string,
    description: PropTypes.string,
    access_code: PropTypes.string
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

Destination.defaultProps = {};

export default Destination;
